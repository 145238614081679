import React from 'react';
import Text from '@last-rev/component-library/dist/components/Text';
import FadeIn from '../FadeIn';
export type { TextProps, TextClassKey, TextClasses } from '@last-rev/component-library/dist/components/Text';

const AnimatedText = (props: any) => (
  <FadeIn variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }} threshold={0.5} delay={0.3}>
    <Text {...props} />
  </FadeIn>
);
export default AnimatedText;
